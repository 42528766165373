<template>
    <v-layout wrap>
        <v-flex xs12>
            <span class="display-1 px-5 pb-5 font-weight-medium">Module</span>
        </v-flex>
        <v-flex
            :key="category.name"
            lg3
            md4
            pa-5
            sm6
            v-for="category in data"
            xs12
        >
            <v-card @click="open(category)" class="module-card" height="300px">
                <v-layout align-center column fill-height justify-center>
                    <v-icon class="module-icon" color="secondary">{{
                        category.meta.icon
                    }}</v-icon>

                    <div class="text-center mt-10 font-weight-medium">
                        {{ category.name }}
                    </div>
                </v-layout>
            </v-card>
        </v-flex>
        <v-dialog
            @keydown.esc="close"
            persistent
            v-if="dialog"
            v-model="dialog"
            width="900px"
        >
            <v-card>
                <v-card-title>
                    <v-spacer></v-spacer>
                    <v-btn @click="close" icon>
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text class="text-center">
                    <p class="pt-2 px-2 display-1">{{ category.name }}</p>
                    <p class="headline font-italic text--lighten-5">
                        Municipiul Gherla
                    </p>
                </v-card-text>

                <v-card-text>
                    <v-layout
                        :key="registry.name"
                        @click="go(registry.slug)"
                        align-center
                        class="registry-wrapper"
                        justify-between
                        my-2
                        px-2
                        py-3
                        v-for="registry in registries"
                    >
                        <v-flex class="font-weight-bold">{{
                            registry.name
                        }}</v-flex>
                        <v-flex class="flex-grow-0">
                            <v-icon>mdi-chevron-right</v-icon>
                        </v-flex>
                    </v-layout>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-layout>
</template>

<script>
export default {
    name: "dashboard",
    metaInfo: {
        title: "Meniu -"
    },
    data: () => ({
        data: [],
        registries: [],
        category: {},
        dialog: false
    }),
    methods: {
        async getData() {
            const response = await this.axios.get("/categories");
            this.data = response.data;
        },
        async getRegistries(id) {
            const response = await this.axios.get(`/registries/${id}`);
            this.registries = [...response.data];
        },
        async open(category) {
            await this.getRegistries(category.id);
            this.category = category;
            this.dialog = true;
        },
        async close() {
            this.dialog = false;
            this.registries = [];
        },
        go(slug) {
            this.$router.push({
                name: "registry",
                query: {
                    slug
                }
            });
        }
    },
    mounted() {
        this.getData();
    }
};
</script>

<style lang="scss" scoped>
.module-icon {
    font-size: 6.5rem;
}

.module-card {
    border-radius: 10px !important;

    &:hover {
        i {
            color: white !important;
            transition: none !important;
        }

        color: white;
        background-color: var(--v-secondary-base);
        cursor: pointer;
    }
}

.registry-wrapper {
    border: 2px solid gray;
    height: 85px;

    &:hover {
        color: white;
        background-color: var(--v-secondary-base);
        cursor: pointer;
        border-color: var(--v-primary-base);
        transition: all 0.2s linear;

        i {
            color: white;
            transition: all 0.2s linear;
        }
    }
}
</style>
